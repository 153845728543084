import React from 'react';

import {
  TextField,
  WrapperField,
  ReferenceField,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import useHasUserPermissions from '../hooks/useHasUserPermissions';
import useIsCreditor from '../hooks/useIsCreditor';

import { useConstantContext } from '../ConstantsContext';

import CurrencyField from '../utils/CurrencyField';
import DropDownMenu from '../../utils/DropDownMenu';
import FinancerName from '../utils/FinancerName';
import hasUserPermissions from '../../utils/hasUserPermissions';

import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import Datagrid from '../designSystem/react-admin/Datagrid';
import DateField from '../designSystem/react-admin/DateField';

import DownloadAccountBreakdownButton from '../entityv2/DownloadAccountBreakdownButton';
import BillingAccountBreakdown from '../entityv2/BillingAccountBreakdown';
import BalanceInSettlementButton from '../entityv2/BalanceInSettlementButton';
import MarkAsInvoicedButton from '../entityv2/MarkAsInvoicedButton';
import { MarkInvoiceAsPaid } from '../entityv2/MarkAsPaidButton';
import SendInvoiceButton from '../entityv2/SendInvoiceButton';

const InvoicedBillingSubActions = () => {
  const record = useRecordContext();

  const isCreditor = useIsCreditor();

  const authenticationData = usePermissions();
  const { userAllowedPermissions } = authenticationData.permissions || {};

  const { constants } = useConstantContext();
  const { userActions } = constants;

  const canEdit = hasUserPermissions(userAllowedPermissions, userActions.MANAGE_INVOICES);

  const mainInvoice = record.invoices[0] || { id: undefined };

  return (
    <DropDownMenu>
      {canEdit && <MarkInvoiceAsPaid isCreditor={isCreditor} accountId={record.id} invoiceId={mainInvoice.id} accountType={record.accountType} />}
      {canEdit && <BalanceInSettlementButton />}
      <DownloadAccountBreakdownButton variant="subAction" />
    </DropDownMenu>
  );
};

export const OpenDatagrid = ({
  shouldShowMerchantName,
  showFinancerName,
  showPartnerName,
}) => {
  const { t } = useTranslation();

  return (
    <Datagrid
      rightAlignedColumns={['feeVAT', 'balance', 'subActions']}
      bulkActionButtons={false}
      expand={<BillingAccountBreakdown />}
      expandSingle
    >
      {shouldShowMerchantName && (
        <EntityReferenceField
          label={t('Merchant')}
          source="debtorId"
          reference="entity-v2"
          sortable={false}
        />
      )}
      {showFinancerName && <FinancerName label={t('Financer')} />}
      {showPartnerName && (
        <ReferenceField
          label={t('Partner')}
          source="partnerId"
          reference="entity-v2/core"
          emptyText="-"
          link={false}
        >
          <TextField source="name" label={t('Partner')} emptyText="-" />
        </ReferenceField>
      )}
      <CurrencyField source="feeVAT" currencySource="currency" label={t('Fee VAT')} emptyText="-" sortable={false} />
      <CurrencyField source="balance" currencySource="currency" label={t('Balance (inc. VAT)')} sortable={false} />
      <WrapperField source="subActions" label={false}>
        <DropDownMenu>
          <DownloadAccountBreakdownButton variant="subAction" />
        </DropDownMenu>
      </WrapperField>
    </Datagrid>
  );
};

OpenDatagrid.propTypes = {
  shouldShowMerchantName: PropTypes.bool,
  showFinancerName: PropTypes.bool,
  showPartnerName: PropTypes.bool,
};

OpenDatagrid.defaultProps = {
  shouldShowMerchantName: false,
  showFinancerName: false,
  showPartnerName: false,
};

export const ReadyToChargeDatagrid = ({
  shouldShowMerchantName,
  showFinancerName,
  showPartnerName,
}) => {
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEdit = useHasUserPermissions(
    constants,
    userActions.MANAGE_BILLING_ACCOUNTS,
  );

  return (
    <Datagrid
      rightAlignedColumns={['balance', 'subActions', 'feeVAT']}
      bulkActionButtons={false}
      expand={<BillingAccountBreakdown />}
      expandSingle
    >
      {shouldShowMerchantName && (
        <EntityReferenceField
          label={t('Merchant')}
          source="debtorId"
          reference="entity-v2"
          sortable={false}
        />
      )}
      {showFinancerName && <FinancerName label={t('Financer')} />}
      {showPartnerName && (
        <ReferenceField
          label={t('Partner')}
          source="partnerId"
          reference="entity-v2/core"
          emptyText="-"
          link={false}
        >
          <TextField source="name" label={t('Partner')} emptyText="-" />
        </ReferenceField>
      )}
      <CurrencyField source="feeVAT" currencySource="currency" label={t('Fee VAT')} emptyText="-" sortable={false} />
      <CurrencyField source="balance" currencySource="currency" label={t('Balance (inc. VAT)')} sortable={false} />
      <WrapperField source="subActions" label={false}>
        <DropDownMenu>
          {canEdit && <SendInvoiceButton />}
          {canEdit && <BalanceInSettlementButton />}
          {canEdit && <MarkAsInvoicedButton />}
          <DownloadAccountBreakdownButton variant="subAction" />
        </DropDownMenu>
      </WrapperField>
    </Datagrid>
  );
};

ReadyToChargeDatagrid.propTypes = {
  shouldShowMerchantName: PropTypes.bool,
  showFinancerName: PropTypes.bool,
  showPartnerName: PropTypes.bool,
};

ReadyToChargeDatagrid.defaultProps = {
  shouldShowMerchantName: false,
  showFinancerName: false,
  showPartnerName: false,
};

export const InvoicedDatagrid = ({
  shouldShowMerchantName,
  showFinancerName,
  showPartnerName,
}) => {
  const { t } = useTranslation();

  return (
    <Datagrid
      rightAlignedColumns={['balance', 'subActions']}
      bulkActionButtons={false}
      expand={<BillingAccountBreakdown />}
      expandSingle
    >
      {shouldShowMerchantName && (
        <EntityReferenceField
          label={t('Merchant')}
          source="debtorId"
          reference="entity-v2"
          sortable={false}
        />
      )}
      {showFinancerName && <FinancerName label={t('Financer')} />}
      {showPartnerName && (
        <ReferenceField
          label={t('Partner')}
          source="partnerId"
          reference="entity-v2/core"
          emptyText="-"
          link={false}
        >
          <TextField source="name" label={t('Partner')} emptyText="-" />
        </ReferenceField>
      )}
      <DateField source="invoices[0].expiryDate" emptyText="-" label={t('Due date')} />
      <DateField source="parkedAt" emptyText="-" label={t('Invoiced date')} />
      <CurrencyField source="balance" currencySource="currency" label={t('Invoiced amount (inc. VAT)')} sortable={false} />
      <WrapperField source="subActions" label={false}>
        <InvoicedBillingSubActions />
      </WrapperField>
    </Datagrid>
  );
};

InvoicedDatagrid.propTypes = {
  shouldShowMerchantName: PropTypes.bool,
  showFinancerName: PropTypes.bool,
  showPartnerName: PropTypes.bool,
};

InvoicedDatagrid.defaultProps = {
  shouldShowMerchantName: false,
  showFinancerName: false,
  showPartnerName: false,
};

export const ClosedDatagrid = ({
  shouldShowMerchantName,
  showFinancerName,
  showPartnerName,
}) => {
  const { t } = useTranslation();

  return (
    <Datagrid
      rightAlignedColumns={[
        'lastTransaction\\.amount',
        'subActions']}
      bulkActionButtons={false}
      expand={<BillingAccountBreakdown />}
      expandSingle
    >
      {shouldShowMerchantName && (
        <EntityReferenceField
          label={t('Merchant')}
          source="debtorId"
          reference="entity-v2"
          sortable={false}
        />
      )}
      {showFinancerName && <FinancerName label={t('Financer')} />}
      {showPartnerName && (
        <ReferenceField
          label={t('Partner')}
          source="partnerId"
          reference="entity-v2/core"
          emptyText="-"
          link={false}
        >
          <TextField source="name" label={t('Partner')} emptyText="-" />
        </ReferenceField>
      )}
      <DateField source="closedAt" emptyText="-" label={t('Closed date')} />
      <CurrencyField
        source="lastTransaction.amount"
        currencySource="currency"
        label={t('Payin amount')}
        emptyText="-"
        sortable={false}
      />
      <WrapperField source="subActions" label={false}>
        <DropDownMenu>
          <DownloadAccountBreakdownButton variant="subAction" />
        </DropDownMenu>
      </WrapperField>
    </Datagrid>
  );
};

ClosedDatagrid.propTypes = {
  shouldShowMerchantName: PropTypes.bool,
  showFinancerName: PropTypes.bool,
  showPartnerName: PropTypes.bool,
};

ClosedDatagrid.defaultProps = {
  shouldShowMerchantName: false,
  showFinancerName: false,
  showPartnerName: false,
};
